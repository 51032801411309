export const useFilters = (
    filters,
    /*
    [
        {
            name: filter_project
            needsSelect2: true/false
            column: 3
            filterFunc?: (element, table) => {}  (if this is defined, column isn't used)
            placeholder: Project
        }
    ]
     */
    table,
    pageName = "",
) => {

    $("#toggle_filter").click(function() {
        if ($("#filter_div").css("display") == "none") {
            $("#filter_div").css("display", "flex");
        } else {
            $("#filter_div").css("display", "none");
        }
    });

    $("#toggle_filters").click(function() {
        $("#filter_div").toggleClass("display-none");
        $("#filter_div").toggleClass("flex-row");
        $("#filter_icon").toggleClass("fa-caret-square-down");
        $("#filter_icon").toggleClass("fa-caret-square-up");
    });

    filters.forEach(filter => {
        const sessionSaveName = pageName + "." + filter["name"];
        const el = document.querySelector(`#${filter["name"]}`);

        // load values from session storage
        const storageVal = sessionStorage.getItem(sessionSaveName);
        if (storageVal !== null) el.value = storageVal;

        // init select 2 if necessary
        if (filter["needsSelect2"]) {
            $(el).select2({
                placeholder: filter["placeholder"],
                width: "100%",
                allowClear: true,
                containerCssClass: "filterselect"
            })
        }

        // init event listeners to perform searches
        $(`#${filter["name"]}`).on("keyup change", function () {
            // save entered search text to session storage
            sessionStorage.setItem(sessionSaveName, $(this).val());
            // perform the filtering
            if (filter["filterFunc"]) {
                return filter["filterFunc"]($(this), table);
            }
            table.column(
                filter["column"]
            ).search(
                $(this).val()
            ).draw();
        });

        if (el) {
            el.dispatchEvent(new Event("change"));
        } else {
            $(`#${filter["name"]}`).trigger("change");
        }

    });

};
