<template>
    <div id="repeatables-board" class="repeatables-board">
        <div class="left-col">
            <div class="task-card" :class="{'is-active': isCreatingNewTask}" @click="clickCreateNew">create new</div>
            <div
                v-for="task in active_tasks" :key="task.id"
                class="task-card" :class="{'is-active': currentTaskId === task.id}"
                @click="clickTaskCard(task)">
                {{ task.name }}
            </div>
        </div>
        <div class="right-col">
            <div class="form-wrapper">
                <Text
                    input-name="task_name"
                    :is-required="true"
                    label-text="TASK NAME"
                    placeholder="Task name"
                    v-model="currentTaskValues.name"
                    :error="errors.name"
                />
                <Text
                    input-name="description"
                    label-text="DESCRIPTION"
                    placeholder="Description"
                    v-model="currentTaskValues.description"
                    :multiline="true"
                />
                <Dropdown
                    input-name="users"
                    :options="all_admins"
                    :is-required="true"
                    v-model="currentTaskValues.user_id"
                    label-text="USER"
                    placeholder="User"
                />
                <Dropdown
                    input-name="projects"
                    :options="all_projects"
                    :is-required="true"
                    v-model="currentTaskValues.project_id"
                    label-text="PROJECT"
                    placeholder="Project"
                    :error="errors.project"
                />
                <div class="form-group row">
                    <label class="col-3" for="day">Day of month</label>
                    <div class="col-3 input-container align-left" id="dayContainer">
                        <input
                            type="number"
                            v-model="currentTaskValues.day"
                            name="day"
                            id="day"
                            class="required"
                            placeholder="Day"
                            required="required"
                            min="1"
                            max="31"
                            step="1"
                        >
                    </div>

                    <label class="col-3" for="days_until_due">Days until due</label>
                    <div class="col-3 input-container align-left">
                        <input
                            type="number"
                            step="1"
                            v-model="currentTaskValues.days_until_due"
                            name="days_until_due"
                            id="days_until_due"
                            placeholder="Days until due"
                        >
                    </div>
                </div>
                <div class="d-flex flex--end mt-3">
                    <button type="button" class="modal-delete" @click="removeCurrentTask" v-if="! isCreatingNewTask">Delete</button>
                    <button type="button" class="modal-update" @click="saveCurrentTask">Save</button>
                </div>
            </div>
        </div>
        <SavingIcon
            :is-saving="isSaving"
        />
    </div>
</template>

<script>

import SavingIcon from "../../util/SavingIcon";
import Dropdown from "../../util/form-elements/Dropdown";
import Text from "../../util/form-elements/Text";

export default {
    name: "Repeatables-Board",
    components: {
        SavingIcon,
        Dropdown,
        Text,
    },
    props: {
        tasks: Array,
        current_user_id: String,
        current_project_id: String,
        all_projects: Array,
        all_admins: Array,
    },
    data() {
        return {
            isCreatingNewTask: true,
            currentTaskId: "",
            active_tasks: this.tasks,
            currentTaskValues: {
                name: "",
                description: "",
                project_id: "" + this.current_project_id,
                user_id: "" + this.current_user_id,
                day: 1,
                days_until_due: "",
            },
            errors: {
                name: "",
                project: "",
            },
            isSaving: false,
        }
    },
    computed: {

    },
    methods: {
        clickCreateNew: function() {
            this.isCreatingNewTask = true;
            this.currentTaskId = "";
            this.resetCurrentTaskValues();
        },
        clickTaskCard: function(task) {
            this.isCreatingNewTask = false;
            this.currentTaskId = task.id;
            this.currentTaskValues = task;
            // user_id and project_id to string, because else v-model doesn't like the value
            if (typeof this.currentTaskValues.user_id === "number") this.currentTaskValues.user_id = '' + this.currentTaskValues.user_id;
            if (typeof this.currentTaskValues.project_id === "number") this.currentTaskValues.project_id = '' + this.currentTaskValues.project_id;
        },
        saveCurrentTask: async function() {
            console.debug("Will save now...")
            this.isSaving = true;
            if (this.isCreatingNewTask) {
                // store new task
                try {
                    const response = await axios.post(route("ajax.tasks.repeatable.store"), {
                        ...this.currentTaskValues
                    });
                    this.active_tasks = response.data
                } catch (err) {
                    console.error(err.response.data.errors);
                    if (err.response.data.errors.name) {
                        this.errors.name = `Name is required`;
                    }
                    if (err.response.data.errors.project_id) {
                        this.errors.project = `Project is required`;
                    }
                }
            } else {
                // save changes to existing task
                try {
                    const response = await axios.post(route("ajax.tasks.repeatable.update"), {
                        ...this.currentTaskValues
                    });
                    this.active_tasks = response.data;
                } catch (err) {
                    console.error(err.response.data.errors);
                }
            }
            this.isSaving = false;
        },
        removeCurrentTask: async function() {
            // remove = set inactive
            this.isSaving = true;
            try {
                const response = await axios.post(route("ajax.tasks.repeatable.destroy"), {
                    ...this.currentTaskValues
                });
                this.active_tasks = response.data
            } catch (err) {
                console.error(err);
                console.error(err.response.data.errors);
            }
            this.isSaving = false;
        },
        resetCurrentTaskValues: function() {
            this.currentTaskValues = {
                name: "",
                description: "",
                project_id: "" + this.current_project_id,
                user_id: "" + this.current_user_id,
                day: 1,
                days_until_due: "",
            }
        }
    },
}

</script>
