<template>
    <div class="modal fade" :id="modalId" >
        <div class="modal-dialog" :class="{ 'modal-lg': isLarge }" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ title }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <slot></slot>
            </div>
            <div class="modal-footer">
                <button @click="checkSave" type="button" class="modal-update">{{ buttonText }}</button>
                <button class="d-none" ref="closeButton" data-dismiss="modal"></button>
                <button type="button" class="modal-delete" data-dismiss="modal">Close</button>
            </div>
        </div>
        </div>
    </div>
</template>
<script>

import { ref } from "vue"

export default {
    props: {
        modalId: {
            type: String,
            default: "modal"
        },
        isLarge: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: "Title"
        },
        buttonText: {
            type: String,
            default: "Save"
        },
        onSave: {
            type: Function,
            default: async () => {}
        }
    },
    setup() {
        const closeButton = ref(null);
        return {
            closeButton,
        }
    },
    methods: {
        checkSave: async function() {
            /*
            Why this method?
            If we just call onSave directly, the modal will close even if the validation fails.
            We want the modal to stay open in that case so the user can see error messages.
             */
            if (await this.onSave()) {
                this.closeButton.click();
            }
        }
    }
}

</script>
