<template>
    <div class="form-group row">
        <label class="col-3" :for="selectName">{{ labelText }}</label>
        <div class="col-9 pl-0 pr-0 align-left" :id="containerId">
            <div class="select-container">
                <select
                    :name="selectName"
                    :id="selectName"
                    :placeholder="placeholder"
                    autocomplete="off"
                    :required="isRequired"
                    :class="{ 'is-invalid': error.length, 'required': isRequired }"
                    v-bind="modelValue"
                    @input="onChanged"
                >

                    <option
                        v-for="option in options"
                        :value="option.id"

                    >
                        {{ option.name }}
                    </option>

                </select>
            </div>
            <span v-if="error" class="invalid-feedback" role="alert">
                <strong>
                    {{ error }}
                </strong>
            </span>
        </div>
    </div>

</template>

<script>
export default {
    props: {
        selectName: {
            type: String,
            default: "input"
        },
        labelText: {
            type: String,
            default: ""
        },
        containerId: {
            type: String,
            default: ""
        },
        options: {
            type: Array,
            default: [],
        },
        modelValue: String,
        error: {
            type: String,
            default: ""
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ""
        },
        multiselect: {
            type: Boolean,
            default: false
        }
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        function onChanged(e) {
            emit('update:modelValue', e.currentTarget.value);
        }
        return {
            onChanged
        }
    }
}
</script>
