<template>

    <div class="dashboard flex flex-column">
        <div class="mb-3 top-row">
            <div class="tasks">
                <h4>
                    Current Tasks
                </h4>
                <ul
                    v-for="task in tasks"
                    class="task-list"
                >
                    <li class="task">
                        <span>{{ task.name }}</span>
                        <span>due {{ (new Date(task.due_date)).toLocaleDateString() }}</span>
                    </li>
                </ul>
            </div>
            <div class="notifications">
                <h4>
                    Recent Notifications
                </h4>
                <ul
                    v-for="notification in notification_list"
                >
                    <li class="notification">
                        <a :href="notification.ref_link" title="mark as read">{{ notification.content }}</a>
                        <img :src="this.$assets('images/dashboard/checkmark.svg')" class="mr-2" @click="setNotificationAsRead(notification.id)">
                    </li>
                </ul>
            </div>
        </div>

        <div class="full-width bottom-row">
            <h4>
                Project overview
            </h4>
            <p>Was genau soll hier angezeigt werden? Einfach die Projekte?</p>
        </div>
    </div>

</template>
<script>

export default {
    name: "Dashboard",
    components: {

    },
    props: {
        tasks: Object,
        notifications: Object,
        current_user_id: String,
        current_project_id: String,
        all_projects: Array,
    },
    data() {
        return {
            notification_list: this.notifications,
        }
    },
    methods: {
        setNotificationAsRead: async function(notification_id) {
            const response = axios.post(
                route("ajax.notifications.set_read", notification_id)
            ).then(response => {
                if (response.status === 200) {
                    this.notification_list = this.notification_list.filter(el => el.id !== notification_id);
                }
            }).catch(error => {
                console.err(error);
            });
        }
    }
}

</script>
