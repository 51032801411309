<template>

    <div class="saving-icon" :class="{ 'saving-active': isSaving }">
        <div class="saving-balls">
            <div class="saving-balls__item" />
            <div class="saving-balls__item" />
            <div class="saving-balls__item" />
        </div>
    </div>

</template>

<script>
export default {
    name: "SavingIcon",
    props: {
        isSaving: Boolean
    }
}
</script>
