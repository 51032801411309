<template>
    <div id="board" class="kanban-board">

        <div class="d-flex flex-row">
            <button class="create_new mr-3" @click="createNew" data-toggle="modal" data-target="#create_new_task_modal">
                <img :src="this.$assets('images/buttons/create-new.svg')" class="mr-2">
                Create New Task
            </button>
            <button class="create_new mr-3" @click="editSwimlanes" data-toggle="modal" data-target="#edit_swimlanes_modal">
                <img :src="this.$assets('images/kanban-board/settings.svg')" class="mr-2">
                Edit Swimlanes
            </button>
            <button class="create_new mr-3" @click="finishAllDoneTasks">
                <img :src="this.$assets('images/kanban-board/checkbox.svg')" class="mr-2">
                Finish All Done Tasks
            </button>

        </div>

        <div class="flex justify-center">
            <div class="min-h-screen flex overflow-x-scroll py-12">
                <div
                    v-for="column in swimlanesWithTasks"
                    :key="column.title"
                    class="bg-gray-100 rounded-lg px-3 py-3 rounded mr-4"
                >
                    <p class="text-gray-700 font-semibold font-sans tracking-wide text-sm">{{column.title}}</p>
                    <draggable
                        class="list-group"
                        :list="column.tasks"
                        group="tasks"
                        itemKey="id"
                        @change="handleTaskMove"
                    >

                        <template #item="{element}">
                            <task-card
                                :key="element.id"
                                :task="element"
                                class="mt-3 cursor-move"
                                @click="handleTaskCardClick(element.id)"
                                data-toggle="modal"
                                data-target="#task_details_modal"
                            >

                            </task-card>
                        </template>

                    </draggable>
                </div>
            </div>
        </div>

        <ModalDialog
            modal-id="task_details_modal"
            :is-large="true"
            title="Task"
            :on-save="saveTaskDetails"
        >
            <div class="details-page">
                <div class="detail-col left-col">
                    <Text
                        input-name="task_name"
                        :is-required="true"
                        label-text="TASK NAME"
                        placeholder="Task name"
                        v-model="currentDetailTask.name"
                    />
                    <ckeditor
                        v-model="currentDetailTask.description"
                        :config="editorConfig"
                        :editor="descriptionEditor"
                    ></ckeditor>
                    <div class="comment-section mt-2 text-left">
                        <div
                            v-for="comment in currentDetailTask.comments"
                            class="comment shadow"
                        >
                            <div class="content mr-2">{{ comment.comment }}</div>
                            <div class="author">{{ getNameById(comment.user_id, all_admins) }}</div>
                            <div class="created-at">{{ comment.created_at }}</div>
                        </div>
                        <div class="form-group row">
                            <input
                                type="text"
                                v-model="currentCommentToAdd"
                                class="new-comment"
                                @keyup.enter="addNewComment"
                            />
                            <button class="update" @click="addNewComment">Add comment</button>
                        </div>

                    </div>
                </div>
                <div class="detail-col right-col">
                    <div class="form-group">
                        <div class="select-container">
                            <select
                                v-model="currentDetailTask.current_lane"
                                class="swimlane-select"
                            >
                                <option
                                    v-for="option in swimlanesForDropdown"
                                    :value="option.id"
                                >
                                    {{ option.name }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="select-container">
                            <select
                                v-model="currentDetailTask.user_id"
                            >
                                <option
                                    v-for="option in all_admins"
                                    :value="option.id"
                                >
                                    {{ option.name }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="select-container">
                            <select
                                v-model="currentDetailTask.project_id"
                            >
                                <option
                                    v-for="option in all_projects"
                                    :value="option.id"
                                >
                                    {{ option.name }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group">
                        <input
                            type="date"
                            v-model="currentDetailTask.due_date"
                        />
                    </div>

                    <div class="flex flex--end">
                        <button
                            class="neutral"
                            @click="finishCurrentDetailTask"
                            data-dismiss="modal"
                        >
                            FINISH
                        </button>
                    </div>

                </div>
            </div>

        </ModalDialog>

        <ModalDialog
            v-if="showCreateNewModal"
            modal-id="create_new_task_modal"
            title="Create new task"
            :on-save="saveCreateNew"
        >
            <Text
                input-name="task_name"
                :is-required="true"
                label-text="TASK NAME"
                placeholder="Task name"
                v-model="newTaskValues.name"
                :error="newTaskErrors.name"
            />
            <Text
                input-name="description"
                label-text="DESCRIPTION"
                placeholder="Description"
                v-model="newTaskValues.description"
                :multiline="true"
            />
            <Dropdown
                input-name="users"
                :options="all_admins"
                :is-required="true"
                v-model="newTaskValues.user_id"
                label-text="USER"
                placeholder="User"
            />
            <Dropdown
                input-name="projects"
                :options="all_projects"
                :is-required="true"
                v-model="newTaskValues.project_id"
                label-text="PROJECT"
                placeholder="Project"
                :error="newTaskErrors.project"
            />
            <Text
                input-name="due_date"
                label-text="DUE DATE"
                placeholder="Due date"
                v-model="newTaskValues.due_date"
                input-type="date"
            />
            <Dropdown
                input-name="swim_lane"
                :options="swimlanesForDropdown"
                v-model="newTaskValues.current_lane"
                label-text="SWIM LANE"
                placeholder="Current swim lane"
            />
        </ModalDialog>

        <ModalDialog
            v-if="showEditSwimlanesModal"
            modal-id="edit_swimlanes_modal"
            title="Edit swimlanes"
            :on-save="saveEditSwimlanes"
        >
            <draggable
                :list="editedSwimlanes"
                item-key="id"
                class="list-group"
            >
                <template #item="{element}">
                    <div
                        :id="element.id"
                        :key="element.id"
                        class="hoverable flex flex--space-between flex-align-center"
                    >
                        {{ element.title }}
                        <button @click="handleDeleteSwimlane(element.id)">
                            <img :src="this.$assets('images/datatables/xmark.svg')" class="config-action ml-2"/>
                        </button>
                    </div>
                </template>
            </draggable>
            <div class="form-group row">
                <input
                    type="text"
                    v-model="newSwimlaneName"
                    class="col-9"
                />
                <button
                    class="col-3 create_new"
                    @click="handleNewSwimlane"
                >
                    <img :src="this.$assets('images/kanban-board/arrow-up.svg')" class="mr-2 config-action">
                    Add
                </button>
            </div>
        </ModalDialog>
        <SavingIcon
            :is-saving="isSaving"
        />
    </div>
</template>

<script>
import draggable from "vuedraggable";
import TaskCard from "./components/TaskCard.vue";
import ModalDialog from "../util/ModalDialog";
import Text from "../util/form-elements/Text";
import Dropdown from "../util/form-elements/Dropdown";
import {debounce} from "lodash";
import SavingIcon from "../util/SavingIcon";
import {useEditorConfig} from "../../modules/editor";
import {getNameById} from "../../modules/helpers";

export default {
    name: "Board",
    components: {
        SavingIcon,
        Dropdown,
        Text,
        ModalDialog,
        TaskCard,
        draggable
    },
    props: {
        tasks: Object,
        current_user_id: String,
        current_project_id: String,
        all_projects: Array,
        all_admins: Array,
        swimlanesProp: Array,
    },
    data() {
        return {
            columns: Object.values(this.tasks),
            showCreateNewModal: false,
            showCreateNewRepeatableModal: false,
            showEditSwimlanesModal: false,
            currentDetailTask: {
                name: "",
                description: "",
                current_lane: "",
                comments: [],
            },
            currentCommentToAdd: "",
            descriptionEditor: ClassicEditor,
            editorConfig: useEditorConfig(),
            isSaving: false,
            taskIdsToSave: new Set(),
            // swimlanes = current database values
            swimlanes: this.swimlanesProp,
            // editedSwimlanes = current frontend state before saving
            editedSwimlanes: this.swimlanesProp,
            swimlanesToDelete: [],
            newSwimlaneName: "",
            newTaskValues: {
                name: "",
                description: "",
                project_id: this.current_project_id,
                user_id: this.current_user_id,
                due_date: "",
                current_lane: ""
            },
            newTaskErrors: {
                name: "",
                project: "",
            }
        };
    },
    computed: {
        swimlanesWithTasks() {
            const self = this;
            return this.columns.map(column => {
                const lane = self.swimlanes.find(el => el.id === column.id);
                if (lane !== undefined) {
                    column.order = lane.order;
                    column.title = lane.title;
                } else {
                    column.title = "Backlog"
                    column.order = "-1"
                }
                return column;
            }).sort((el1, el2) => el1.order - el2.order);
        },
        swimlanesForDropdown() {
            return this.swimlanes.map(lane => {
                lane.name = lane.title;
                return lane;
            }).sort((el1, el2) => el1.order - el2.order);
        }
    },
    watch: {
    },
    methods: {
        getNameById,
        resetNewTaskValues: function() {
            this.newTaskValues = {
                name: "",
                description: "",
                project_id: this.current_project_id,
                user_id: this.current_user_id,
                due_date: "",
                current_lane: ""
            }
        },
        fetchTasks: async function() {
            const response = axios.get(
                route("ajax.tasks"),
            ).then(response => {
                console.debug(response);
                this.columns = Object.values(response.data);
                this.showCreateNewModal = false;
            }).catch(err => {
                console.error(err);
            })
            /*if (response.ok) {
                const json = await response.json();
                this.columns = json;
            } else {
                console.error(response.status);
            }*/
        },
        createNew: function() {
            this.showCreateNewModal = true;
            this.newTaskErrors = {
                name: "",
                project: "",
            }
        },
        saveCreateNew: async function() {
            try {
                const response = await axios.post(route("tasks.store"), {
                    ...this.newTaskValues
                });
                await this.fetchTasks();
                this.resetNewTaskValues();
                return true;
            } catch (err) {
                console.error(err.response.data.errors);
                if (err.response.data.errors.name) {
                    this.newTaskErrors.name = `Name is required`;
                }
                if (err.response.data.errors.project_id) {
                    this.newTaskErrors.project = `Project is required`;
                }
                return false;
            }
        },
        finishAllDoneTasks: async function() {
            this.isSaving = true;
            this.columns.forEach(swimlane => {
                if (swimlane.title === "DONE") {
                    swimlane.tasks.forEach(task => {
                        task.finished = 1;
                        this.taskIdsToSave.add(task.id);
                    });
                }
            });
            this.saveTaskChanges();
        },
        handleTaskMove: function(event) {
            if (event.added) {
                const id = event.added.element.id;
                this.taskIdsToSave.add(id);
                this.isSaving = true;
                this.saveTaskChanges();
            }
        },
        saveTaskChanges: debounce(function() {
            const idsToSave = this.taskIdsToSave;
            this.taskIdsToSave = new Set();
            const tasksToSave = [];
            idsToSave.forEach(id => {
                this.columns.every(col => {
                    const el = col.tasks.find(tas => {
                        return tas.id === id;
                    });
                    if (el) {
                        el.current_lane = col.id;
                        tasksToSave.push(el);
                        return false;
                    }
                    return true;
                });
            });
            axios.post(
                route("ajax.tasks.update"),
                {
                    "tasks": tasksToSave
                }
            ).then(response => {
                this.columns = Object.values(response.data);
            }).catch(err => {
                console.error(err);
            }).finally(() => {
                this.isSaving = false;
            });
            return true;
        }, 2000),
        handleTaskCardClick: function(id) {
            this.setTaskDetails(id);
        },
        setTaskDetails: function(id) {
            const self = this;
            this.columns.every(col => {
                const found = col.tasks.find(task => task.id === id);
                if (found) {
                    self.currentDetailTask = found;
                    self.currentDetailTask.current_lane = col.id;
                    // to prevent error when initializing ckeditor instance
                    if (self.currentDetailTask.description === null) self.currentDetailTask.description = "";
                    return false;
                }
                return true;
            });
        },
        finishCurrentDetailTask: function () {
            this.currentDetailTask.finished = 1;
            this.saveTaskDetails();
        },
        saveTaskDetails: function() {
            this.isSaving = true;
            const self = this;
            // update local columns if task column was changed
            this.columns = this.columns.map(col => {
                col.tasks = col.tasks.filter(task => task.id !== self.currentDetailTask.id);
                if (col.id === self.currentDetailTask.current_lane) {
                    col.tasks.push(self.currentDetailTask);
                }
                return col;
            });
            this.taskIdsToSave.add(this.currentDetailTask.id);
            this.saveTaskChanges();
            return true;
        },
        addNewComment: function() {
            if (! this.currentCommentToAdd) return;  // don't add comment if no text has been entered
            this.currentDetailTask.comments.push({
                "comment": this.currentCommentToAdd,
                "task_id": this.currentDetailTask.id,
                "user_id": this.current_user_id,
                "created_at": "A few moments ago"
            });
            this.currentCommentToAdd = "";
        },
        editSwimlanes: function() {
            this.showEditSwimlanesModal = true;
        },
        handleNewSwimlane: function() {
            if (! this.newSwimlaneName) return;  // don't add new swimlane if no name has been entered
            this.editedSwimlanes.push({
                "title": this.newSwimlaneName,
                "order": this.editedSwimlanes.length
            });
            this.newSwimlaneName = "";
        },
        handleDeleteSwimlane: function(id) {
            this.editedSwimlanes = this.editedSwimlanes.filter(el => {
                return el.id !== id;
            });
            this.swimlanesToDelete.push(id);
        },
        saveEditSwimlanes: async function() {
            this.isSaving = true;
            const swimLanesToSave = this.editedSwimlanes.map((el, index) => {
                el.order = index + 1;
                return el;
            });
            try {
                const response = await axios.post(route("ajax.tasks.swimlanes.update"), {
                    "swimlanes": swimLanesToSave,
                    "deleteSwimlanes": this.swimlanesToDelete
                });
                this.swimlanes = response.data;
                this.editedSwimlanes = this.swimlanes;
                await this.fetchTasks();
                this.isSaving = false;
                return true;
            } catch (err) {
                console.error(err);
                return false;
            }
        }
    }
};
</script>
