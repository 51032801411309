<template>
    <div class="form-group row">
        <label class="col-3" :for="inputName">{{ labelText }}</label>
        <div class="col-9 input-container align-left" :id="containerId">
            <textarea
                v-if="multiline"
                @input="onChanged"
                :name="inputName"
                :value="modelValue"
            ></textarea>
            <input
                v-else
                :type="inputType"
                @input="onChanged"
                :value="modelValue"
                :name="inputName"
                :class="{ 'is-invalid': error.length, 'required': isRequired }"
                :placeholder="placeholder"
                :required="isRequired"
            >
            <span v-if="error" class="invalid-feedback" role="alert">
                <strong>
                    {{ error }}
                </strong>
            </span>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        inputName: {
            type: String,
            default: "input"
        },
        labelText: {
            type: String,
            default: ""
        },
        containerId: {
            type: String,
            default: ""
        },
        modelValue: String,
        error: {
            type: String,
            default: ""
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ""
        },
        inputType: {
            type: String,
            default: "text"
        },
        multiline: {
            type: Boolean,
            default: false
        }
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        function onChanged(e) {
            emit('update:modelValue', e.currentTarget.value);
        }
        return {
            onChanged
        }
    }
}
</script>
