export function goBackWithRefresh() {
    window.history.back();
    if ('referrer' in document) {
        window.location = document.referrer;
    } else {
        window.history.back();
    }
}

export function isEmail(email) {
    // https://stackoverflow.com/a/2507043/7080781
    let regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
}

export function getBaseUrl(full) {
    let pathArray = full.split("/");
    return pathArray[2];
}

export function getNameById(id, collection) {
    for (const item of collection) {
        if ("" + item.id ===  "" + id) {
            return item.name;
        }
    }
    return "";
}
