<template>
    <div class="shadow rounded px-3 pt-3 pb-3 hoverable task-card">
        <div class="flex justify-between flex-column">
            <span class="font-semibold tracking-wide text-sm pb-3">{{ task.name }}</span>
            <span v-if="task.due_date" class="text-sm text-gray">Due: {{ task.due_date }}</span>
            <span v-if="task.project_name" class="text-sm text-gray">Project: {{ task.project_name }}</span>
        </div>
    </div>
</template>
<script>
export default {
    components: {
    },
    props: {
        task: {
            type: Object,
            default: () => ({})
        }
    },
    created() {
    },
    computed: {
    }
};
</script>
